import { DownloadIcon, VideoIcon } from "lucide-react";
import React, { useState } from "react";

import { message, Spin, Modal, Radio } from "antd";
import axios from "axios";
import { APIStandards } from "../../../../../lib/api_standards";
import { Button } from "../../../../../components/button";
import { Separator } from "../../../../../components/seperator";

async function fetchStatus(
  instanceId: string,
  attemptscount: number,
  setLoading: Function,
  setCountdown: Function,
  setNewDataAvailable: Function,
  setLink: Function,
  type: string
) {
  let attempts = 0;
  const apiUrl =
    type === "top"
      ? `${APIStandards.APP.GENERATE_VIDEO_TOP}?instance_id=${instanceId}&type=status`
      : `${APIStandards.APP.GENERATE_VIDEO_BOTTOM}?instance_id=${instanceId}&type=status`;
  const maxAttempts = 5000; // Max retries
  while (attempts < maxAttempts) {
    try {
      const res = await axios.get(apiUrl);
      console.log("Status API response: ", res.data);
      const result = res.data;

      if (result.success) {
        if (result.link) {
          setNewDataAvailable(true);
          setLink(result.link);
          message.success("Video is ready!");
          setLoading(false);
          return;
        } else {
          // Link not yet available, wait and retry
          await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait 2 seconds
          setCountdown((prev: number) => Math.max(prev - 5, 0)); // Decrease countdown
        }
      } else {
        message.error("Failed to generate video.");
        setLoading(false);
        return;
      }
    } catch (error) {
      console.error("Error checking video status:", error);
      message.error("Error while fetching video status.");
      setLoading(false);
      return;
    }
    attempts++;
  }
  message.error("Timeout while waiting for video generation.");
  setLoading(false);
}

function GroundTruthButtonInsights({ instance, disabled }: any) {
  const [loadingTop, setLoadingTop] = useState(false);
  const [countdownTop, setCountdownTop] = useState(40); // Default 40 seconds
  const [newDataAvailableTop, setNewDataAvailableTop] = useState(false);
  const [linkTop, setLinkTop] = useState("");

  const [loadingBottom, setLoadingBottom] = useState(false);
  const [countdownBottom, setCountdownBottom] = useState(40); // Default 40 seconds
  const [newDataAvailableBottom, setNewDataAvailableBottom] = useState(false);
  const [linkBottom, setLinkBottom] = useState("");

  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedSize, setSelectedSize] = useState("");
  const processblobcount = instance.raw_count / 2;
  const blobcount = processblobcount / 20;
  const blobprocessdur = blobcount * 10;

  const handleGenerate = async (
    instanceId: string,
    processduration: number,
    setLoading: Function,
    setCountdown: Function,
    setNewDataAvailable: Function,
    setLink: Function,
    size: string,
    type: string
  ) => {
    setLoading(true);
    setCountdown(processduration); // Reset countdown
    const apiUrl =
      type === "top"
        ? `${APIStandards.APP.GENERATE_VIDEO_TOP}?instance_id=${instanceId}&type=generate&size=${size}`
        : `${APIStandards.APP.GENERATE_VIDEO_BOTTOM}?instance_id=${instanceId}&type=generate&size=${size}`;

    try {
      console.log("size : ", size);

      const res = await axios.get(apiUrl);

      console.log("Generate API response: ", res.data);
      const result = res.data;

      if (result.success) {
        if (
          result.message === "Video Generating" ||
          result.message === "Task already in progress"
        ) {
          // Call status API in a loop until the video is ready
          await fetchStatus(
            instanceId,
            processduration / 2,
            setLoading,
            setCountdown,
            setNewDataAvailable,
            setLink,
            type
          );
        } else if (result.link) {
          // If link is directly available
          setNewDataAvailable(true);
          setLink(result.link);
          message.success("Video is ready!");
        } else {
          message.error("Unexpected response format.");
        }
      } else {
        message.error("Failed to generate video.");
      }
    } catch (error) {
      console.error("Error generating video:", error);
      message.error("Error while generating video.");
    }
    setLoading(false);
  };

  const handleSizeSelection = () => {
    if (!selectedSize) {
      message.warning("Please select a size before proceeding!");
      return;
    }
    console.log("Selected Size:", selectedSize);
    setPopupVisible(false); // Close the popup
    if (loadingTop) {
      handleGenerate(
        instance.container_id,
        blobprocessdur,
        setLoadingTop,
        setCountdownTop,
        setNewDataAvailableTop,
        setLinkTop,
        selectedSize,
        "top"
      );
    } else if (loadingBottom) {
      handleGenerate(
        instance.container_id,
        blobprocessdur,
        setLoadingBottom,
        setCountdownBottom,
        setNewDataAvailableBottom,
        setLinkBottom,
        selectedSize,
        "bottom"
      );
    }
  };

  const handleSizeSelectionClose = () => {
    setLoadingBottom(false);
    setLoadingTop(false);
    setPopupVisible(false);
  };

  return (
    <div className="flex flex-col gap-2">
      {/* Top Button Section */}
      <div className="flex flex-col items-center">
        <div className="mb-1">
          <div className=" text-gray-700 items-center flex justify-center">
            <div className="flex flex-row gap-1 items-center">
              <div className="text-[12px]">Container</div>
              <div className=" text-[8px] text-gray-500">(Ground Truth)</div>
            </div>
          </div>
        </div>
        <Separator className="mb-1" />
        {newDataAvailableTop || instance.top_ground_truth ? (
          <Button
            onClick={() => {
              Object.assign(document.createElement("a"), {
                target: "_blank",
                rel: "noopener noreferrer",
                href: newDataAvailableTop ? linkTop : instance.top_ground_truth,
              }).click();
            }}
            style={{ backgroundColor: "#00e600" }}
          >
            <DownloadIcon />
          </Button>
        ) : loadingTop ? (
          <div className="flex flex-col items-center">
            <Spin tip="Loading..." />
            {/* <p className="mt-2 text-sm text-gray-600">ETA: {countdownTop}s</p> */}
          </div>
        ) : (
          <Button
            disabled={disabled}
            onClick={() => {
              setLoadingTop(true);
              setPopupVisible(true);
            }}
            className="flex flex-col"
          >
            <VideoIcon />
          </Button>
        )}
      </div>

      {/* Bottom Button Section */}
      <div className="flex flex-col items-center">
        <div className="mb-1">
          <div className=" text-gray-700 items-center flex justify-center">
            <div className="flex flex-row gap-1 items-center">
              <div className="text-[12px]">Wagon</div>
              <div className=" text-[8px] text-gray-500">(Ground Truth)</div>
            </div>
          </div>
        </div>
        <Separator className="mb-1" />
        {newDataAvailableBottom || instance.bot_ground_truth ? (
          <Button
            onClick={() => {
              Object.assign(document.createElement("a"), {
                target: "_blank",
                rel: "noopener noreferrer",
                href: newDataAvailableBottom
                  ? linkBottom
                  : instance.bot_ground_truth,
              }).click();
            }}
            style={{ backgroundColor: "#00e600" }}
          >
            <DownloadIcon />
          </Button>
        ) : loadingBottom ? (
          <div className="flex flex-col items-center">
            <Spin tip="Loading..." />
            {/* <p className="mt-2 text-sm text-gray-600">
              ETA: {countdownBottom}s
            </p> */}
          </div>
        ) : (
          <Button
            disabled={disabled}
            onClick={() => {
              setLoadingBottom(true);
              setPopupVisible(true);
            }}
            className="flex flex-col"
          >
            <VideoIcon />
          </Button>
        )}
      </div>

      {/* Modal for Size Selection */}
      <Modal
        title="Select Video Size"
        visible={popupVisible}
        onCancel={handleSizeSelectionClose}
        onOk={handleSizeSelection}
      >
        <Radio.Group
          onChange={(e) => setSelectedSize(e.target.value)}
          value={selectedSize}
        >
          <Radio value="org">Original</Radio>
          {/* <Radio value="1074x2448">1074x2448</Radio> */}
          <Radio value="med">Medium</Radio>
          <Radio value="low">Low</Radio>
        </Radio.Group>
      </Modal>
    </div>
  );
}

export default GroundTruthButtonInsights;
