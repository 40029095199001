"use client";

import { ColumnDef } from "@tanstack/react-table";
import { Button } from "../../../../../components/button";
import { ArrowUpDown } from "lucide-react";

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
export type Train = {
  train_id: string;
  container_count: number;
  wagon_count: number;
  in_time: string;
  out_time: string;
  // wagon_id: number;
  wagon_no: number;
  wagon_type: string;
  container_code: string;
  container_type: string;
  container_position: string;
  ic_code: string;
  dimension: string;
  shipping_name: string;
  warning_hazard: string;
  container_sequence: number;
  container_sort: number;
};

export const train_columns: ColumnDef<Train>[] = [
  // {
  //   accessorKey: "wagon_id",
  //   header: () => <div>Wagon ID</div>,
  //   cell: ({ row }) => {
  //     const train = row.original;
  //     return (
  //       // <CellWithNavigation
  //       //   instanceId={`${instance.container_id}/${instance.container_id}/${instance.container_id}`}
  //       // >
  //       <p>{train.wagon_id ? train.wagon_id : "-"}</p>
  //       // </CellWithNavigation>
  //     );
  //   },
  // },
  // {
  //   id: "serial",
  //   header: "#",
  //   cell: (info) => info.row.index + 1,
  // },
  {
    accessorKey: "container_sort",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => {
            const isSorted = column.getIsSorted();

            if (!isSorted) {
              column.toggleSorting(true);
            } else if (isSorted === "desc") {
              column.toggleSorting(false);
            } else {
              column.toggleSorting(true);
            }
          }}
        >
          Sequence
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      const train = row.original;
      console.log(train);

      return (
        <p className="flex items-center justify-center">
          {train.container_sequence != null
            ? train.container_sequence == 0
              ? ""
              : train.container_sequence
            : ""}
        </p>
      );
    },
  },
  {
    accessorKey: "wagon_no",
    header: () => <div>Wagon No.</div>,
    cell: ({ row }) => {
      function separateNumberAndContent(str: any) {
        const regex = /^(\d+)\s*(\([^)]+\))$/;
        const match = str.match(regex);
        if (match) {
          const numberBeforeParenthesis = match[1];
          const contentInParenthesis = match[2];

          return { numberBeforeParenthesis, contentInParenthesis };
        } else {
          return null;
        }
      }

      const train = row.original;
      return (
        <p>
          {train.wagon_no ? (
            <div className="flex flex-row gap-2">
              <div>
                {separateNumberAndContent(train.wagon_no.toString())
                  ?.numberBeforeParenthesis || train.wagon_no}
              </div>
              <div className=" text-gray-500">
                {
                  separateNumberAndContent(train.wagon_no.toString())
                    ?.contentInParenthesis
                }
              </div>
            </div>
          ) : (
            ""
          )}
        </p>
      );
    },
  },
  {
    accessorKey: "wagon_type",
    header: () => <div>Wagon Type</div>,
    cell: ({ row }) => {
      const train = row.original;
      return <p>{train.wagon_type ? train.wagon_type : ""}</p>;
    },
  },

  {
    accessorKey: "container_code",
    header: () => <div>Container Code</div>,
    cell: ({ row }) => {
      const train = row.original;
      return <p>{train.container_code ? train.container_code : ""}</p>;
    },
  },
  {
    accessorKey: "container_type",
    header: () => <div>Container Type</div>,
    cell: ({ row }) => {
      const train = row.original;
      return <p>{train.container_type ? train.container_type : ""}</p>;
    },
  },
  {
    accessorKey: "container_position",
    header: () => <div>Container Position Id</div>,
    cell: ({ row }) => {
      const train = row.original;
      return <p>{train.container_position ? train.container_position : ""}</p>;
    },
  },
  {
    accessorKey: "ic_code",
    header: () => <div>IC Code</div>,
    cell: ({ row }) => {
      const train = row.original;
      return <p>{train.ic_code ? train.ic_code : ""}</p>;
    },
  },
  {
    accessorKey: "dimension",
    header: () => <div>Dimension</div>,
    cell: ({ row }) => {
      const train = row.original;
      return <p>{train.dimension ? train.dimension : ""}</p>;
    },
  },
  {
    accessorKey: "shipping_name",
    header: () => <div>Shipping Company Name</div>,
    cell: ({ row }) => {
      const train = row.original;
      return <p>{train.shipping_name ? train.shipping_name : ""}</p>;
    },
  },
  {
    accessorKey: "warning_hazard",
    header: () => <div>Warning/Hazard</div>,
    cell: ({ row }) => {
      const train = row.original;
      return <p>{train.warning_hazard ? train.warning_hazard : ""}</p>;
    },
  },
];
