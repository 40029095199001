import React, { useEffect, useState } from "react";
import { LoginOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Button,
  Typography,
  message,
  Modal,
  Spin,
} from "antd";
import { useActionData, useNavigate } from "react-router-dom";
import axios from "axios";
import { APIStandards } from "../../../../../lib/api_standards";
// import APIStandards from "../../../../utils/API_standards";

function Login() {
  //   const { state } = useAppContext();
  const [passcode, setPasscode] = useState("");
  const [trialPeriod, settrialPeriod] = useState(false);
  const [bversion, setbversion] = useState("");
  const [validUser, setvalidUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [unlockAppVisible, setUnlockAppVisible] = useState(false);

  const onFinish = (values: any) => {
    setLoading(true);
    login(values.username, values.password);

    // if (values.username!='admin' || values.password !='admin') {
    //   message.error('Invalid username and password, please try again')
    // }

    // else{
    //   settrialPeriod(true)
    //   // navigate('/user/home')}

    // }
    // Implement your login logic here
  };
  // const openModal =() => setUnlockAppVisible(true);
  // const closeModal = () => setUnlockAppVisible(false);
  // const overideControl = (passcode : String) => {
  //   if(passcode!="rai2021")
  //   {
  //     message.error('Invalid Passcode')
  //   }
  //   else{
  //     closeModal()
  //     navigate('/user/home')
  //   }
  // }
  // const getProjectDetails =async()=>{
  //   const projectList = await project

  // }

  const login = (username: any, password: any) => {
    const formData = new FormData();
    formData.append("email", username);
    formData.append("password", password);
    axios
      .post(APIStandards.USER.LOGIN, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(async (data: any) => {
        const result = JSON.stringify(data.data.user);
        if (data.data.success === true) {
          sessionStorage.setItem("activeUser", JSON.stringify(data.data.user));
          if (
            data.data.user.role === "admin" ||
            data.data.user.role === "dev"
          ) {
            navigate("/admin");
          } else if (data.data.user.role === "user") {
            // const formData = new FormData();
            // formData.append("project_id", data.data.user.projectAssigned);
            // const projectsData = await getProjectDetails(formData)
            // localStorage.setItem("activeProject", JSON.stringify(projectsData));
            navigate("/user");
          } else {
            navigate("/login");
          }
          setLoading(false);
          setvalidUser(true);
        }
      })
      .catch((ex: any) => {
        message.error("Failed to login, please try again!");
        setLoading(false);
      });
  };
  // const getVersionBackend= () => {
  //   communication_service
  //     .post(APIStandards.USER.GET_BACKEND_VERSION, {})
  //     .then((data: any) => {
  //       setbversion(data.data.Version);
  //     })
  //     .catch((ex: any) => {
  //       message.error("Failed to retrieve backend version, please try again!");
  //     });

  // }
  useEffect(() => {
    // getVersionBackend();
  }, []);

  return (
    <Spin spinning={loading}>
      <Row justify="center" align="middle">
        <Col span={18}>
          <Typography.Title
            className="mb-4"
            level={3}
            style={{ textAlign: "center" }}
          >
            Login
          </Typography.Title>
          <Form className="mt-4" name="loginForm" onFinish={onFinish}>
            <Form.Item
              name="username"
              label="Username"
              rules={[
                {
                  required: true,
                  message: "Please enter your username",
                },
              ]}
            >
              <Input placeholder="Username" />
            </Form.Item>

            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>

            <Form.Item>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                size="large"
                icon={<LoginOutlined />}
                htmlType="submit"
                block
              >
                Login
              </Button>
            </Form.Item>
          </Form>

          <Modal
            width={"40%"}
            footer={null}
            open={trialPeriod}
            closable={true}
            onCancel={() => {
              settrialPeriod(false);
            }}
          >
            {/* <TrialPeriodForm /> */}
          </Modal>

          <div style={{ textAlign: "center" }}>
            <a
              href="#"
              style={{
                pointerEvents: "none",
                color: "grey",
                textDecoration: "none",
              }}
            >
              Forgot password
            </a>
            <span style={{ margin: "0 10px", color: "grey" }}>|</span>
            <a
              href="#"
              style={{
                pointerEvents: "none",
                color: "grey",
                textDecoration: "none",
              }}
            >
              Create new account
            </a>
          </div>
          {/* <div style={{ textAlign: 'center' }}> */}
          {/* <a href="#" onClick={openModal}>Unlock</a></div>
          <Form className='mt-4' name="passcode" onFinish={overideControl}>
          <Modal
        title="Unlock the app"
        closable={true} 
        open={unlockAppVisible}
        onCancel={() => setUnlockAppVisible(false)}

      > */}
          {/* <Input.Password 
   type="text" 
   id="passcode" 
   name="passcode"
   onChange={ e => setPasscode(e.target.value) }/> */}
          {/* <Form.Item
              name="pin"
              label="Passcode"
              rules={[
                {
                  required: true,
                  message: 'Please enter the unlock pin',
                },
              ]}
            ></Form.Item> */}
          {/* <Input.Password placeholder="Password" name="pin"/> */}
          {/* <Button type="primary" htmlType="submit"  onClick= {() =>overideControl(passcode)}>
           Submit
               
              </Button>
            
            
      </Modal>
      </Form> */}

          {/* <p>Fv 0.1  Bv {bversion}</p> */}
        </Col>
      </Row>
    </Spin>
  );
}

export default Login;
