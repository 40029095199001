"use client";

import { TrendingUp } from "lucide-react";
import {
  CartesianGrid,
  LabelList,
  Line,
  LineChart,
  XAxis,
  YAxis,
} from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../../../../components/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../../../../components/chart";

const chartData = [
  { train: "1", container: 186 },
  { train: "2", container: 305 },
  { train: "3", container: 237 },
  { train: "4", container: 73 },
  { train: "5", container: 209 },
  { train: "6", container: 214 },
];

const chartConfig = {
  train: {
    label: "train",
    color: "hsl(var(--chart-1))",
  },
} satisfies ChartConfig;

type Props = {};

const InstanceWiseContainerRatioGraph = ({ data }: any) => {
  return (
    <div className="max-h-[40vh]">
      <Card className="max-h-[40vh]">
        <CardHeader>
          <CardTitle>Containers Ratio by Train</CardTitle>
          {/* <CardDescription>1 - June 2024</CardDescription> */}
        </CardHeader>
        <CardContent className="max-h-[40vh]">
          <ChartContainer
            config={chartConfig}
            className="max-h-[20vh] w-[37vw]"
          >
            <LineChart
              accessibilityLayer
              data={data}
              margin={{
                top: 20,
                left: 12,
                right: 12,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                className="hidden"
                dataKey="train"
                tickLine={false}
                axisLine={false}
                tickMargin={8}
              />
              <YAxis
                dataKey="ratio"
                tickLine={false}
                axisLine={false}
                tickMargin={8}
              />
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent indicator="line" />}
              />
              <Line
                dataKey="ratio"
                type="natural"
                stroke="var(--color-train)"
                strokeWidth={2}
                dot={{
                  fill: "var(--color-train)",
                }}
                activeDot={{
                  r: 6,
                }}
              >
                <LabelList
                  position="top"
                  offset={12}
                  className="fill-foreground"
                  fontSize={12}
                />
              </Line>
            </LineChart>
          </ChartContainer>
        </CardContent>
      </Card>
    </div>
  );
};

export default InstanceWiseContainerRatioGraph;
