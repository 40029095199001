import * as React from "react";
import { cn } from "../lib/utils";

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
  <div className="relative w-full overflow-auto">
    <table
      ref={ref}
      className={cn("w-full caption-bottom text-sm", className)}
      {...props}
    />
  </div>
));
Table.displayName = "Table";

// const TableHeader = React.forwardRef<
//   HTMLTableSectionElement,
//   React.HTMLAttributes<HTMLTableSectionElement>
// >(({ className, ...props }, ref) => (
//   <thead
//     ref={ref}
//     className={cn("w-full", "[&_tr]:border-b-wid", className)}
//     style={{ height: "auto" }} // Allow auto-height to ensure no overflow
//     {...props}
//   >
//     <tr className="sticky top-0 bg-blue z-10">
//       <th className="h-12 px-4 text-left align-middle text-lg text-white font-bold">
//         Instance ID
//       </th>
//       <th className="h-12 px-4 text-left align-middle text-lg text-white font-bold">
//         Type
//       </th>
//       <th className="h-12 px-4 text-left align-middle text-lg text-white font-bold">
//         Timeline
//       </th>
//       <th className="h-12 px-7 text-left align-middle text-lg text-white font-bold">
//         Created At
//       </th>
//       <th className="h-12 px-4 text-left align-middle text-lg text-white font-bold">
//         Processed At
//       </th>
//       <th className="h-12 px-3 text-left align-middle text-lg text-white font-bold">
//         Elapsed Time
//       </th>
//       {/* <th className="h-12 px-4 text-left align-middle text-lg text-white font-bold">
//         Wagon Count
//       </th>
//       <th className="h-12 px-4 text-left align-middle text-lg text-white font-bold">
//         Container Count
//       </th> */}
//       <th className="h-12 px-4 text-left align-middle text-lg text-white font-bold">
//         Raw Count
//       </th>
//       <th className="h-12 px-5 text-left align-middle text-lg text-white font-bold">
//         Ground Truth
//       </th>
//       <th className="h-12 px-4 text-left align-middle text-lg text-white font-bold">
//         Report
//       </th>
//     </tr>

//     {/* Subrow or secondary header */}
//     <tr className="bg-gray-200">
//       <th className="h-6 px-8 text-center text-xs text-gray-500"></th>
//       <th className="h-6 px-1 text-center text-xs text-gray-500">
//         container/<br></br>engine/others<br></br>
//       </th>
//       <th className="h-6 px-4 text-left text-xs text-gray-500">day/night</th>
//       <th className="h-6 px-4 text-left text-xs text-gray-500">
//         dd-mm-yyyy-hh-mm
//       </th>
//       <th className="h-6 px-4 text-left text-xs text-gray-500">
//         dd-mm-yyyy-hh-mm
//       </th>
//       <th className="h-6 px-4 text-left text-xs text-gray-500">hh-mm-ss</th>
//       {/* <th className="h-6 px-4 text-left text-xs text-gray-500">nos</th>
//       <th className="h-6 px-4 text-left text-xs text-gray-500">nos</th> */}
//       <th className="h-6 px-4 text-left text-xs text-gray-500">nos</th>
//       {/* <th className="h-6 px-4 text-left text-xs text-gray-500">TopBottom</th> */}
//       <th className="h-6 px-5 py-4 text-left text-xs text-gray-500">
//         container&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;wagon
//       </th>
//       <th className="h-6 px-7 text-left text-xs text-gray-500">xls</th>
//     </tr>
//   </thead>
// ));
const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead ref={ref} className={cn("[&_tr]:border-b", className)} {...props} />
));
TableHeader.displayName = "TableHeader";

// TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn("[&_tr:last-child]:border-0", className)}
    {...props}
  />
));
TableBody.displayName = "TableBody";

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn(
      "border-t bg-muted/50 font-medium [&>tr]:last:border-b-0",
      className
    )}
    {...props}
  />
));
TableFooter.displayName = "TableFooter";

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={cn(
      "border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted",
      className
    )}
    {...props}
  />
));
TableRow.displayName = "TableRow";

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      "h-12 px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0",
      className
    )}
    {...props}
  />
));
TableHead.displayName = "TableHead";

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn("p-4 align-middle [&:has([role=checkbox])]:pr-0", className)}
    {...props}
  />
));
TableCell.displayName = "TableCell";

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn("mt-4 text-sm text-muted-foreground", className)}
    {...props}
  />
));
TableCaption.displayName = "TableCaption";

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
};
