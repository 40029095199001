import { useEffect, useState } from "react";

import axios from "axios";
import { DataTable } from "../components/table/data-table";
import { columns } from "../components/table/columns";
import { Button, Spin } from "antd";
import { useLocation } from "react-router-dom";
import { RefreshCcw } from "lucide-react";
import { APIStandards } from "../../../../../lib/api_standards";
import { format } from "date-fns";

// async function getData(): Promise<Instance[]> {

//   // Fetch data from your API here.
//   return InstanceList;
// }

// export default async function InstanceList2() {
// const data = await getData();

// return (
//   <div className="container mx-auto py-10">
//     <DataTable columns={columns} data={data} />
//   </div>
// );
// }

function DataCollectionList() {
  const [InstanceList, setInstanceList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const pathname = useLocation();
  const [refreshedTime, setLastRefreshedTime] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [userDetails, setUserDetails] = useState(
    JSON.parse(sessionStorage.getItem("activeUser") || "{}")
  );
  // useEffect(() => {
  //   (async () => {
  //     const res = await axios.get(
  //       "http://localhost:8000/api/container_vision/instance/?date=all"
  //     );
  //     const data = res.data;
  //     setInstanceList(data);
  //     setIsLoading(false);
  //   })();
  // }, []);
  const fetchImagesStreaming = async () => {
    setIsLoading(true);
    const date = format(selectedDate, "yyyy-MM-dd").trim();

    try {
      setInstanceList([]);
      // Use Fetch API to make a request to the streaming endpoint
      const response = await fetch(
        `${APIStandards.APP.GET_INSTANCES}?date=${date}&user_id=${userDetails.userID}`,
        {
          method: "GET",
        }
      );

      if (!response.body) {
        throw new Error("ReadableStream not supported or empty response body.");
      }

      // Process the stream using a TextDecoder
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");

      let done = false;
      let buffer = ""; // Buffer to accumulate partial chunks

      while (!done) {
        const { value, done: streamDone } = await reader.read();
        done = streamDone;

        if (value) {
          // Decode the chunk and append it to the buffer
          buffer += decoder.decode(value, { stream: true });

          // Split buffer into complete JSON objects by newline
          const lines = buffer.split("\n");
          buffer = lines.pop()!; // Save incomplete JSON for the next chunk

          // Parse and append each complete JSON object
          const parsedData = lines.map((line) => JSON.parse(line));
          setInstanceList((prev: any) => [...prev, ...parsedData]);
          setIsLoading(false);
        }
      }

      // Handle any remaining data in the buffer (if it's a complete JSON object)
      if (buffer.trim()) {
        setInstanceList((prev: any) => [...prev, JSON.parse(buffer)]);
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    console.log(InstanceList);
  }, [InstanceList]);

  useEffect(() => {
    if (userDetails) {
      fetchImagesStreaming();

      // Reset refresh timer
      const timerId = setInterval(() => {
        setLastRefreshedTime((prevTime) => prevTime + 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [selectedDate, userDetails]);

  // const handleLoadMore = () => {
  //   if (hasMore && !isLoading) {
  //     setPage((prev) => prev + 1);
  //   }
  // };
  return (
    <div
      className={` mx-4 py-10 ${
        pathname.pathname === "/admin/collected-data"
          ? "min-h-[100vh] max-h-[100vh] overflow-y-auto"
          : ""
      }`}
    >
      {isLoading && (
        <div className="flex mx-auto items-center min-h-[85vh] max-h-[85vh] justify-center">
          <Spin />
        </div>
      )}
      {!isLoading && (
        <div className={`flex flex-col gap-5 `}>
          <div className="flex flex-row justify-between">
            <div className=" text-3xl font-bold tracking-wide">
              Instances ({InstanceList.length})
            </div>
            <div className="flex flex-row gap-2 items-center">
              <div>Last Refreshed {refreshedTime}s ago</div>
              <div>
                <Button
                  icon={<RefreshCcw />}
                  onClick={() => {
                    setInstanceList([]);
                    fetchImagesStreaming();
                  }}
                >
                  Refresh
                </Button>
              </div>
            </div>
          </div>
          <div>
            {" "}
            <DataTable
              columns={columns}
              data={InstanceList}
              setSelectedDate={setSelectedDate}
              selectedDate={selectedDate}
            />
          </div>
          {/* <div>
            {hasMore && (
              <div className="flex justify-center mt-4">
                <Button onClick={handleLoadMore} disabled={isLoading}>
                  Load More
                </Button>
              </div>
            )}
          </div> */}
        </div>
      )}
    </div>
  );
}

export default DataCollectionList;
