import React, { useEffect, useState } from "react";
import { TabsContent } from "../../../../data-visualization/components/tabs";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../../../../components/card";
import {
  ActivityIcon,
  ContainerIcon,
  TrainIcon,
  User2Icon,
} from "lucide-react";
import { BarGraph } from "../../../../data-visualization/components/graphs/Bargraph";
import { AreaGraph } from "../../graphs/areagraph";
import IntanceReportGraph from "../../graphs/instance_report";
import InstanceWiseContainerGraph from "../../graphs/instance_wise_container_graph";
import { APIStandards } from "../../../../../../../lib/api_standards";
import { message, Spin } from "antd";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../../../components/select";
import { LoadingOutlined } from "@ant-design/icons";
import InstanceWiseContainerRatioGraph from "../../graphs/instance_wise_container_ratio_graph";
import axios from "axios";
type Props = {};

const OverviewTab = (props: Props) => {
  const [timeRange, setTimeRange] = React.useState("7");
  const [isOverviewDataLoading, setisOverviewDataLoading] = useState(false);
  const [isTrainDataLoading, setIsTrainDataLoading] = useState(false);
  const [isTrainWiseContainerDataLoading, setIsTrainWiseContainerDataLoading] =
    useState(false);
  const [
    isTrainWiseContainerRatioDataLoading,
    setIsTrainWiseContainerRatioDataLoading,
  ] = useState(false);
  const [overviewData, setOverviewData] = useState<any>([
    {
      train_count: 0,
      container_count: 0,
      average_train_count: 0,
      average_container_count: 0,
    },
  ]);
  const [trainReportData, setTrainReportData] = React.useState<any>([]);
  const [trainWiseContainerData, settrainWiseContainerData] =
    React.useState<any>([]);
  const [trainWiseContainerRatioData, settrainWiseContainerRatioData] =
    React.useState<any>([]);

  React.useEffect(() => {
    const fetchTrainData = async () => {
      try {
        setTrainReportData([]);
        setIsTrainDataLoading(true);
        const response = await axios.get(
          `${APIStandards.APP.GET_TRAIN_REPORT_GRAPH}?range=${timeRange}`
        );
        if (!response.data) {
          console.error("No response body found.");
          return;
        }

        // const reader = response.body.getReader();
        // const decoder = new TextDecoder("utf-8");
        // let partialChunk = "";

        // while (true) {
        //   const { done, value } = await reader.read();
        //   if (done) break;

        //   partialChunk += decoder.decode(value, { stream: true });
        //   const lines = partialChunk.split("\n");
        //   for (let i = 0; i < lines.length - 1; i++) {
        //     const line = lines[i].trim();
        //     if (line) {
        //       try {
        //         const parsedData = JSON.parse(line);
        //         updateTrainData(parsedData);
        //       } catch (err) {
        //         console.error("Error parsing JSON:", err);
        //       }
        //     }
        //   }

        //   partialChunk = lines[lines.length - 1];
        // }

        // if (partialChunk.trim()) {
        //   try {
        //     const parsedData = JSON.parse(partialChunk);
        //     updateTrainData(parsedData);
        //   } catch (err) {
        //     console.error("Error parsing final JSON chunk:", err);
        //   }
        // }
        setTrainReportData(response.data);
      } catch (error) {
        console.error("Error fetching train data:", error);
      } finally {
        // const sorteddata = trainReportData.sort(
        //   (a: any, b: any) =>
        //     new Date(a.date).getTime() - new Date(b.date).getTime()
        // );
        // setTrainReportData(sorteddata);
        setIsTrainDataLoading(false);
      }
    };

    fetchTrainData();
  }, [timeRange]);
  React.useEffect(() => {
    const fetchTrainWiseContainerData = async () => {
      try {
        settrainWiseContainerData([]);
        setIsTrainWiseContainerDataLoading(true);
        const response = await axios.get(
          `${APIStandards.APP.GET_TRAIN_WISE_CONTAINER_GRAPH}?range=${timeRange}`
        );
        if (!response.data) {
          console.error("No response body found.");
          return;
        }

        // const reader = response.body.getReader();
        // const decoder = new TextDecoder("utf-8");
        // let partialChunk = "";

        // while (true) {
        //   const { done, value } = await reader.read();
        //   if (done) break;

        //   partialChunk += decoder.decode(value, { stream: true });
        //   const lines = partialChunk.split("\n");
        //   for (let i = 0; i < lines.length - 1; i++) {
        //     const line = lines[i].trim();
        //     if (line) {
        //       try {
        //         const parsedData = JSON.parse(line);
        //         updateTrainWiseContainerData(parsedData);
        //       } catch (err) {
        //         console.error("Error parsing JSON:", err);
        //       }
        //     }
        //   }

        //   partialChunk = lines[lines.length - 1];
        // }

        // if (partialChunk.trim()) {
        //   try {
        //     const parsedData = JSON.parse(partialChunk);
        //     updateTrainWiseContainerData(parsedData);
        //   } catch (err) {
        //     console.error("Error parsing final JSON chunk:", err);
        //   }
        // }
        settrainWiseContainerData(response.data);
      } catch (error) {
        console.error("Error fetching train data:", error);
      } finally {
        setIsTrainWiseContainerDataLoading(false);
      }
    };

    fetchTrainWiseContainerData();
  }, [timeRange]);
  React.useEffect(() => {
    const fetchTrainWiseContainerRatioData = async () => {
      try {
        settrainWiseContainerRatioData([]);
        setIsTrainWiseContainerRatioDataLoading(true);
        const response = await axios.get(
          `${APIStandards.APP.GET_TRAIN_WISE_CONTAINER_RATIO_GRAPH}?range=${timeRange}`
        );
        if (!response.data) {
          console.error("No response body found.");
          return;
        }

        // const reader = response.body.getReader();
        // const decoder = new TextDecoder("utf-8");
        // let partialChunk = "";

        // while (true) {
        //   const { done, value } = await reader.read();
        //   if (done) break;

        //   partialChunk += decoder.decode(value, { stream: true });
        //   const lines = partialChunk.split("\n");
        //   for (let i = 0; i < lines.length - 1; i++) {
        //     const line = lines[i].trim();
        //     if (line) {
        //       try {
        //         const parsedData = JSON.parse(line);
        //         updateTrainWiseContainerRatioData(parsedData);
        //       } catch (err) {
        //         console.error("Error parsing JSON:", err);
        //       }
        //     }
        //   }

        //   partialChunk = lines[lines.length - 1];
        // }

        // if (partialChunk.trim()) {
        //   try {
        //     const parsedData = JSON.parse(partialChunk);
        //     updateTrainWiseContainerRatioData(parsedData);
        //   } catch (err) {
        //     console.error("Error parsing final JSON chunk:", err);
        //   }
        // }
        settrainWiseContainerRatioData(response.data);
      } catch (error) {
        console.error("Error fetching train data:", error);
      } finally {
        setIsTrainWiseContainerRatioDataLoading(false);
      }
    };

    fetchTrainWiseContainerRatioData();
  }, [timeRange]);

  const updateTrainData = (newEntry: any) => {
    setTrainReportData((prevData: any) => {
      const existingIndex = prevData.findIndex(
        (entry: any) => entry.date === newEntry.date
      );

      if (existingIndex !== -1) {
        const updatedData = [...prevData];
        updatedData[existingIndex].train = newEntry.train;
        return updatedData;
      } else {
        return [...prevData, newEntry];
      }
    });
  };
  const updateTrainWiseContainerData = (newEntry: any) => {
    settrainWiseContainerData((prevData: any) => {
      const existingIndex = prevData.findIndex(
        (entry: any) => entry.train === newEntry.train
      );

      if (existingIndex !== -1) {
        const updatedData = [...prevData];
        updatedData[existingIndex].container = newEntry.container;
        return updatedData;
      } else {
        return [...prevData, newEntry];
      }
    });
  };
  const updateTrainWiseContainerRatioData = (newEntry: any) => {
    settrainWiseContainerRatioData((prevData: any) => {
      const existingIndex = prevData.findIndex(
        (entry: any) => entry.train === newEntry.train
      );

      if (existingIndex !== -1) {
        const updatedData = [...prevData];
        updatedData[existingIndex].ratio = newEntry.ratio;
        return updatedData;
      } else {
        return [...prevData, newEntry];
      }
    });
  };
  useEffect(() => {
    console.log(trainWiseContainerRatioData);
  }, [trainWiseContainerRatioData]);

  const fetchOverviewDataStreaming = async (range: any) => {
    try {
      setisOverviewDataLoading(true);
      // Use Fetch API to make a request to the streaming endpoint
      const response = await axios.get(
        `${APIStandards.APP.GET_OVERVIEW_DATA}?range=${range}`
      );

      if (!response.data) {
        throw new Error("ReadableStream not supported or empty response body.");
      }

      // const reader = response.body.getReader();
      // const decoder = new TextDecoder("utf-8");

      // let done = false;
      // let buffer = "";

      // while (!done) {
      //   const { value, done: streamDone } = await reader.read();
      //   done = streamDone;

      //   if (value) {
      //     buffer += decoder.decode(value, { stream: true });
      //     const lines = buffer.split("\n");
      //     buffer = lines.pop()!;
      //     const parsedData = lines.map((line) => JSON.parse(line));
      //     setOverviewData(parsedData);
      //   }
      // }

      // if (buffer.trim()) {
      setOverviewData(response.data);
      // }
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      // message.success("overview data loaded!!");
      setisOverviewDataLoading(false);
    }
  };
  useEffect(() => {
    fetchOverviewDataStreaming(timeRange);
  }, [timeRange]);

  return (
    <>
      <TabsContent value="overview" className="space-y-4 max-h-[10vh]">
        <div>
          {" "}
          <Select
            value={timeRange}
            onValueChange={setTimeRange}
            disabled={isOverviewDataLoading || isTrainDataLoading}
          >
            <SelectTrigger
              className="w-[160px] rounded-lg sm:ml-auto"
              aria-label="Select a value"
            >
              <SelectValue placeholder="Last 7 days" />
            </SelectTrigger>
            <SelectContent className="rounded-xl">
              <SelectItem value="90" className="rounded-lg">
                Last 3 months
              </SelectItem>
              <SelectItem value="30" className="rounded-lg">
                Last 30 days
              </SelectItem>
              <SelectItem value="7" className="rounded-lg">
                Last 7 days
              </SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Total Trains
              </CardTitle>
              <Spin
                spinning={isOverviewDataLoading}
                indicator={<LoadingOutlined spin />}
              >
                {isOverviewDataLoading ? null : (
                  <TrainIcon className="text-muted-foreground" />
                )}
              </Spin>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                {overviewData["train_count"] || 0}
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Total Containers
              </CardTitle>
              <ContainerIcon className="text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                {overviewData["container_count"] || 0}
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Average Trains Per Day
              </CardTitle>
              <Spin
                spinning={isOverviewDataLoading}
                indicator={<LoadingOutlined spin />}
              >
                {isOverviewDataLoading ? null : (
                  <ActivityIcon className="text-muted-foreground" />
                )}
              </Spin>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                {overviewData["average_train_count"] || 0}
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Average Containers Per Day
              </CardTitle>
              <ActivityIcon className="text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                {overviewData["average_container_count"] || 0}
              </div>
            </CardContent>
          </Card>
        </div>
        <div>
          <Spin spinning={isTrainDataLoading}>
            <IntanceReportGraph timeRange={timeRange} data={trainReportData} />
          </Spin>
        </div>
        <div className="flex flex-row gap-4 w-full ">
          <div className="w-[50%]">
            <Spin spinning={isTrainWiseContainerDataLoading}>
              <InstanceWiseContainerGraph data={trainWiseContainerData} />
            </Spin>
          </div>
          <div className="w-[49%]">
            <Spin spinning={isTrainWiseContainerRatioDataLoading}>
              <InstanceWiseContainerRatioGraph
                data={trainWiseContainerRatioData}
              />
            </Spin>
          </div>
        </div>
      </TabsContent>
    </>
  );
};

export default OverviewTab;
