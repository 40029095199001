import React, { useEffect, useState } from "react";
import { TabsContent } from "../../../../data-visualization/components/tabs";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../../../../components/card";
import { Clock, ContainerIcon, TrainIcon, User2Icon } from "lucide-react";
import { BarGraph } from "../../../../data-visualization/components/graphs/Bargraph";
import { AreaGraph } from "../../graphs/areagraph";
import { APIStandards } from "../../../../../../../lib/api_standards";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { ContainerByTimeGraph } from "../../graphs/bargraph";
type Props = {};

const ByTrainTab = (props: Props) => {
  const [isOverviewDataLoading, setisOverviewDataLoading] = useState(false);
  const [overviewData, setOverviewData] = useState<any>({
    last_train_id: "fetching",
    container_count: "fetching",
    created_at: "fetching",
  });
  const [containersByTimeData, setContainersByTimeData] = useState([]);
  const [iscontainersByTimeDataLoading, setiscontainersByTimeDataLoading] =
    useState(false);
  const fetchOverviewDataStreaming = async () => {
    try {
      setisOverviewDataLoading(true);
      // Use Fetch API to make a request to the streaming endpoint
      const response = await axios.get(
        `${APIStandards.APP.GET_BYTRAIN_OVERVIEW_DATA}`
      );

      if (!response.data) {
        throw new Error("ReadableStream not supported or empty response body.");
      }
      if (response.data) {
        setOverviewData(response.data);
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      // message.success("overview data loaded!!");
      setisOverviewDataLoading(false);
    }
  };
  useEffect(() => {
    fetchOverviewDataStreaming();
  }, []);
  const formatDate = (isoString: any) => {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return formattedDate;
  };
  React.useEffect(() => {
    const fetchContainersByTime = async () => {
      try {
        setContainersByTimeData([]);
        setiscontainersByTimeDataLoading(true);
        const response = await axios.get(
          `${APIStandards.APP.GET_CONTAINERS_BY_TIME_GRAPH}`
        );
        if (!response.data) {
          console.error("No response body found.");
          return;
        }

        setContainersByTimeData(response.data);
      } catch (error) {
        console.error("Error fetching train data:", error);
      } finally {
        setiscontainersByTimeDataLoading(false);
      }
    };

    fetchContainersByTime();
  }, []);
  return (
    <>
      <TabsContent value="bytrain" className="space-y-4">
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Last Train</CardTitle>
              <Spin
                spinning={isOverviewDataLoading}
                indicator={<LoadingOutlined spin />}
              >
                {isOverviewDataLoading ? null : (
                  <TrainIcon className="text-muted-foreground" />
                )}
              </Spin>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                {overviewData["last_train_id"] || "no data"}
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Total Containers in last train
              </CardTitle>
              <Spin
                spinning={isOverviewDataLoading}
                indicator={<LoadingOutlined spin />}
              >
                {isOverviewDataLoading ? null : (
                  <ContainerIcon className="text-muted-foreground" />
                )}
              </Spin>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                {overviewData["container_count"] || "no data"}
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Timeline</CardTitle>
              <Spin
                spinning={isOverviewDataLoading}
                indicator={<LoadingOutlined spin />}
              >
                {isOverviewDataLoading ? null : (
                  <Clock className="text-muted-foreground" />
                )}
              </Spin>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                {formatDate(overviewData["created_at"]) || "no data"}
              </div>
            </CardContent>
          </Card>
          {/* <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Average Containers Per Day
              </CardTitle>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="h-4 w-4 text-muted-foreground"
              >
                <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
              </svg>
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{0}</div>
            </CardContent>
          </Card> */}
        </div>
        <div className="flex flex-row gap-2">
          <div className="w-[50%]">
            <ContainerByTimeGraph data={containersByTimeData} />
          </div>
          <div className="w-[50%]">
            <ContainerByTimeGraph data={containersByTimeData} />
          </div>
        </div>
      </TabsContent>
    </>
  );
};

export default ByTrainTab;
