"use client";

import { Bar, BarChart, CartesianGrid, XAxis } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../../../components/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../../../../components/chart";

const chartData = [
  { time: "10am", desktop: 186 },
  { time: "11am", desktop: 305 },
  { time: "12am", desktop: 237 },
  { time: "1pm", desktop: 73 },
  { time: "2pm", desktop: 209 },
  { time: "3pm", desktop: 214 },
];

const chartConfig = {
  count: {
    label: "Containers",
    color: "hsl(var(--chart-1))",
  },
} satisfies ChartConfig;

export function ContainerByTimeGraph({ data }: any) {
  return (
    <div className="flex space-x-4">
      {" "}
      {/* Flex container for side-by-side layout */}
      {/* Temperature Data Card */}
      <Card className="flex-1">
        <CardHeader>
          <CardTitle>Containers/Time</CardTitle>
          {/* <CardDescription>September - October 2024</CardDescription> */}
        </CardHeader>
        <CardContent>
          <ChartContainer config={chartConfig}>
            <BarChart accessibilityLayer data={data}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="created_at"
                tickLine={false}
                tickMargin={10}
                axisLine={false}
                tickFormatter={(value) => value.slice(0, 3)}
              />
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent indicator="dashed" />}
              />
              <Bar dataKey="count" fill="var(--color-count)" radius={4} />
            </BarChart>
          </ChartContainer>
        </CardContent>
      </Card>
    </div>
  );
}
