"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../../components/form";

import { Input } from "../../../../../components/input";
import { Button } from "../../../../../components/button";

import { Checkbox } from "../../../../../components/check-box";
import { message, Select } from "antd";
import axios from "axios";
import { APIStandards } from "../../../../../lib/api_standards";
const UserSchema = z.object({
  userID: z.string().email({ message: "Invalid email address" }),
  userName: z
    .string()
    .min(3, { message: "Username must be at least 3 characters long" })
    .max(25, { message: "Username must not exceed 25 characters" })
    .regex(/^[a-zA-Z0-9_]+$/, {
      message: "Username can only contain letters, numbers, and underscores",
    }),
  email: z.string().email({ message: "Invalid email address" }),
  password: z
    .string()
    .min(8, { message: "Password must be at least 8 characters long" })
    .max(20, { message: "Password must not exceed 20 characters" }),
  rolename: z.enum(["admin", "user", "dev"], {
    message: "Role must be either 'admin' or 'user'",
  }),
  status: z.boolean({ required_error: "Status is required" }),
});

type Props = {};

function UserForm({ setisOpenModel, setisuserCreated }: any) {
  const form = useForm<z.infer<typeof UserSchema>>({
    resolver: zodResolver(UserSchema),
    defaultValues: {
      userID: "",
      userName: "",
      email: "",
      password: "",
      rolename: "user",
      status: true,
    },
  });

  // 2. Define a submit handler.
  async function onSubmit(values: z.infer<typeof UserSchema>) {
    const formdata = new FormData();
    formdata.append("userID", values.userID);
    formdata.append("userName", values.userName);
    formdata.append("email", values.email);
    formdata.append("password", values.password);

    formdata.append("rolename", values.rolename);
    formdata.append("status", values.status.toString());
    await axios.post(APIStandards.ADMIN.CREATE_USER, formdata).then((res) => {
      if (res.data["success"]) {
        message.info("User Created!");
        setisOpenModel(false);
        setisuserCreated(true);
      } else {
        message.error("Error!");
      }
    });
  }
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="userID"
          render={({ field }) => (
            <FormItem>
              <FormLabel>User Id</FormLabel>
              <FormControl>
                <Input placeholder="userid" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="userName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>UserName</FormLabel>
              <FormControl>
                <Input placeholder="username" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input type="password" placeholder="password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="rolename"
          render={({ field }) => (
            <FormItem className="flex flex-col  ">
              <FormLabel>Role</FormLabel>
              <FormControl>
                <Select
                  onChange={field.onChange}
                  defaultValue={field.value}
                  className="h-10"
                >
                  <Select.Option value="user">User</Select.Option>
                  <Select.Option value="admin">Admin</Select.Option>
                  <Select.Option value="dev">Developer</Select.Option>
                </Select>
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="status"
          render={({ field }) => (
            <FormItem className="flex flex-row items-start space-x-3 space-y-0  ">
              <FormLabel>Status</FormLabel>
              <FormControl>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <Button type="submit">Submit</Button>
      </form>
    </Form>
  );
}

export default UserForm;
