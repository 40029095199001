"use client";

import * as React from "react";
import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../../../components/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../../components/select";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../../../../components/chart";
import { message } from "antd";
import { APIStandards } from "../../../../../../lib/api_standards";

const chartData = [
  { date: "2024-04-01", train: 222 },
  { date: "2024-04-02", train: 97 },
  { date: "2024-04-03", train: 167 },
  { date: "2024-04-04", train: 242 },
  { date: "2024-04-05", train: 373 },
  { date: "2024-04-06", train: 301 },
  { date: "2024-04-07", train: 245 },
  { date: "2024-04-08", train: 409 },
  { date: "2024-04-09", train: 59 },
  { date: "2024-04-10", train: 261 },
  { date: "2024-04-11", train: 327 },
  { date: "2024-04-12", train: 292 },
  { date: "2024-04-13", train: 342 },
  { date: "2024-04-14", train: 137 },
  { date: "2024-04-15", train: 120 },
  { date: "2024-04-16", train: 138 },
  { date: "2024-04-17", train: 446 },
  { date: "2024-04-18", train: 364 },
  { date: "2024-04-19", train: 243 },
  { date: "2024-04-20", train: 89 },
  { date: "2024-04-21", train: 137 },
  { date: "2024-04-22", train: 224 },
  { date: "2024-04-23", train: 138 },
  { date: "2024-04-24", train: 387 },
  { date: "2024-04-25", train: 215 },
  { date: "2024-04-26", train: 75 },
  { date: "2024-04-27", train: 383 },
  { date: "2024-04-28", train: 122 },
  { date: "2024-04-29", train: 315 },
  { date: "2024-04-30", train: 454 },
  { date: "2024-05-01", train: 165 },
  { date: "2024-05-02", train: 293 },
  { date: "2024-05-03", train: 247 },
  { date: "2024-05-04", train: 385 },
  { date: "2024-05-05", train: 481 },
  { date: "2024-05-06", train: 498 },
  { date: "2024-05-07", train: 388 },
  { date: "2024-05-08", train: 149 },
  { date: "2024-05-09", train: 227 },
  { date: "2024-05-10", train: 293 },
  { date: "2024-05-11", train: 335 },
  { date: "2024-05-12", train: 197 },
  { date: "2024-05-13", train: 197 },
  { date: "2024-05-14", train: 448 },
  { date: "2024-05-15", train: 473 },
  { date: "2024-05-16", train: 338 },
  { date: "2024-05-17", train: 499 },
  { date: "2024-05-18", train: 315 },
  { date: "2024-05-19", train: 235 },
  { date: "2024-05-20", train: 177 },
  { date: "2024-05-21", train: 82 },
  { date: "2024-05-22", train: 81 },
  { date: "2024-05-23", train: 252 },
  { date: "2024-05-24", train: 294 },
  { date: "2024-05-25", train: 201 },
  { date: "2024-05-26", train: 213 },
  { date: "2024-05-27", train: 420 },
  { date: "2024-05-28", train: 233 },
  { date: "2024-05-29", train: 78 },
  { date: "2024-05-30", train: 340 },
  { date: "2024-05-31", train: 178 },
  { date: "2024-06-01", train: 178 },
  { date: "2024-06-02", train: 470 },
  { date: "2024-06-03", train: 103 },
  { date: "2024-06-04", train: 439 },
  { date: "2024-06-05", train: 88 },
  { date: "2024-06-06", train: 294 },
  { date: "2024-06-07", train: 323 },
  { date: "2024-06-08", train: 385 },
  { date: "2024-06-09", train: 438 },
  { date: "2024-06-10", train: 155 },
  { date: "2024-06-11", train: 92 },
  { date: "2024-06-12", train: 492 },
  { date: "2024-06-13", train: 81 },
  { date: "2024-06-14", train: 426 },
  { date: "2024-06-15", train: 307 },
  { date: "2024-06-16", train: 371 },
  { date: "2024-06-17", train: 475 },
  { date: "2024-06-18", train: 107 },
  { date: "2024-06-19", train: 341 },
  { date: "2024-06-20", train: 408 },
  { date: "2024-06-21", train: 169 },
  { date: "2024-06-22", train: 317 },
  { date: "2024-06-23", train: 480 },
  { date: "2024-06-24", train: 132 },
  { date: "2024-10-24", train: 141 },
  { date: "2024-10-25", train: 434 },
  { date: "2024-10-24", train: 448 },
  { date: "2024-11-24", train: 149 },
  { date: "2024-11-25", train: 103 },
  { date: "2024-11-25", train: 446 },
];

const chartConfig = {
  visitors: {
    label: "Visitors",
  },
  train: {
    label: "train",
    color: "hsl(var(--chart-1))",
  },
  mobile: {
    label: "train",
    color: "hsl(var(--chart-2))",
  },
} satisfies ChartConfig;

// type Props = {
//     timeRange:string
// };

const IntanceReportGraph = ({ timeRange, data }: any) => {
  //   const [timeRange, setTimeRange] = React.useState("7d");
  const [description, setDescription] = React.useState("last 7 days");
  const [sortedData, setSortedData] = React.useState([]);
  React.useEffect(() => {
    if (data) {
      setSortedData(
        data.sort(
          (a: any, b: any) =>
            new Date(a.date).getTime() - new Date(b.date).getTime()
        )
      );
    }
  }, [data]);

  //   const [filteredData, setFilteredData] = React.useState<any>([]);
  const filteredData = sortedData.filter((item: any) => {
    const date = new Date(item.date);
    const referenceDate = new Date();
    let daysToSubtract = 90;
    if (timeRange === "30") {
      daysToSubtract = 31;
      //   setDescription("last 30 days");
    } else if (timeRange === "90") {
      daysToSubtract = 91;
      //   setDescription("last 3 months");
    } else if (timeRange === "7") {
      daysToSubtract = 8;
      //   setDescription("last 3 months");
    }
    const startDate = new Date(referenceDate);
    startDate.setDate(startDate.getDate() - daysToSubtract);
    return date >= startDate;
  });
  //   React.useEffect(() => {
  //     message.info("here");
  //     const data = chartData.filter((item) => {
  //       const date = new Date(item.date);
  //       const referenceDate = new Date();
  //       let daysToSubtract = 90;
  //       if (timeRange === "30") {
  //         daysToSubtract = 30;
  //         //   setDescription("last 30 days");
  //       } else if (timeRange === "90") {
  //         daysToSubtract = 90;
  //         //   setDescription("last 3 months");
  //       } else if (timeRange === "7") {
  //         daysToSubtract = 7;
  //         //   setDescription("last 3 months");
  //       }
  //       const startDate = new Date(referenceDate);
  //       startDate.setDate(startDate.getDate() - daysToSubtract);
  //       return date >= startDate;
  //     });
  //     setFilteredData(data);
  //   }, [timeRange]);

  React.useEffect(() => {
    if (timeRange === "90") {
      setDescription("last 3 months");
    } else if (timeRange === "30") {
      setDescription("last 30 days");
    } else if (timeRange === "7") {
      setDescription("last 7 days");
    }
  }, [timeRange]);

  return (
    <Card>
      <CardHeader className="flex items-center gap-2 space-y-0 border-b py-5 sm:flex-row">
        <div className="grid flex-1 gap-1 text-center sm:text-left">
          <CardTitle>Train Report</CardTitle>
          <CardDescription>
            Showing reports for the {description}
          </CardDescription>
        </div>
        {/* <Select value={timeRange} onValueChange={setTimeRange}>
          <SelectTrigger
            className="w-[160px] rounded-lg sm:ml-auto"
            aria-label="Select a value"
          >
            <SelectValue placeholder="Last 7 days" />
          </SelectTrigger>
          <SelectContent className="rounded-xl">
            <SelectItem value="90d" className="rounded-lg">
              Last 3 months
            </SelectItem>
            <SelectItem value="30d" className="rounded-lg">
              Last 30 days
            </SelectItem>
            <SelectItem value="7d" className="rounded-lg">
              Last 7 days
            </SelectItem>
          </SelectContent>
        </Select> */}
      </CardHeader>
      <CardContent className="px-2 pt-4 sm:px-6 sm:pt-6">
        <ChartContainer
          config={chartConfig}
          className="aspect-auto h-[250px] w-full"
        >
          <AreaChart data={filteredData}>
            <defs>
              <linearGradient id="fillDesktop" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor="var(--color-desktop)"
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor="var(--color-desktop)"
                  stopOpacity={0.1}
                />
              </linearGradient>
              <linearGradient id="fillMobile" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor="var(--color-mobile)"
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor="var(--color-mobile)"
                  stopOpacity={0.1}
                />
              </linearGradient>
            </defs>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              minTickGap={32}
              tickFormatter={(value) => {
                const date = new Date(value);
                return date.toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                });
              }}
            />
            <YAxis
              dataKey="train"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              minTickGap={32}
            />
            <ChartTooltip
              cursor={false}
              content={
                <ChartTooltipContent
                  labelFormatter={(value) => {
                    return new Date(value).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                    });
                  }}
                  indicator="dot"
                />
              }
            />
            <Area
              dataKey="train"
              type="natural"
              fill="url(#fillMobile)"
              stroke="var(--color-mobile)"
              stackId="a"
            />

            {/* <ChartLegend content={<ChartLegendContent />} /> */}
          </AreaChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
};

export default IntanceReportGraph;
