import React, { useEffect, useState } from "react";
import { Button } from "../../../../../components/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../../../../components/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/tabs";

import { AreaGraph } from "../components/graphs/areagraph";
import { PieGraph } from "../components/graphs/piegraph";
import axios from "axios";
import {
  ContainerIcon,
  LucideTrendingDown,
  TrainIcon,
  User2Icon,
} from "lucide-react";
import { APIStandards } from "../../../../../lib/api_standards";
import OverviewTab from "../components/tabs/child/overview_tab";
import ByTrainTab from "../components/tabs/child/by_train_tab";
import { useLocation } from "react-router-dom";

type Props = {};

function DashboardScreen({}: Props) {
  const [userDetails, setUserDetails] = useState(
    JSON.parse(sessionStorage.getItem("activeUser") || "{}")
  );
  const pathname = useLocation();
  const userName = userDetails.userName;
  // const [usersCount, setusersCount] = useState<any>(0);
  // const [instancesCount, setinstancesCount] = useState<any>(0);
  // const [containersCount, setcontainersCount] = useState<any>(0);
  // const [containersCountToday, setcontainersCountToday] = useState<any>(0);
  // useEffect(() => {
  //   (async () => {
  //     const res = await axios.get(APIStandards.APP.GET_COUNTS);
  //     const data = res.data;
  //     setusersCount(data.users_count);

  //     setinstancesCount(data.instances_count);

  //     setcontainersCount(data.containers_count);

  //     setcontainersCountToday(data.containers_count_today);
  //   })();
  // }, []);
  return (
    <div className="p-5 max-h-[100vh] min-h-[100vh] overflow-y-scroll">
      <div className="space-y-2">
        <div className="flex items-center justify-between space-y-2">
          <h2 className="text-2xl font-bold tracking-tight">
            Hi,{" "}
            {userName.toString()[0].toUpperCase() +
              userName.toString().substring(1)}{" "}
            👋
          </h2>
          {/* <div className="hidden items-center space-x-2 md:flex">
            <CalendarDateRangePicker />
            <Button>Download</Button>
          </div> */}
        </div>
        <Tabs defaultValue="overview" className="space-y-4">
          <TabsList>
            <TabsTrigger value="overview">Overview</TabsTrigger>
            <TabsTrigger
              value="bytrain"
              disabled={pathname.pathname.includes("user")}
            >
              By Train
            </TabsTrigger>
          </TabsList>
          <OverviewTab />
          <ByTrainTab />
        </Tabs>
      </div>
      <footer
        style={{
          textAlign: "center",
          backgroundColor: "#ffffff",
          color: "gray-300",
          position: "absolute",
          bottom: "0",
          left: "50%",
          fontSize: "14px",
        }}
      >
        <p>
          Copyrights &copy; {new Date().getFullYear()} RoshAi. All Rights
          Reserved.
        </p>
      </footer>
    </div>
  );
}
export default DashboardScreen;
