import { Col, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import HeaderComponent from "../../components/header";
import { SideBar } from "./features/dashboard/components/sidebar";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandShortcut,
} from "../../components/command";
import { LayoutDashboard, Lightbulb, Train, User } from "lucide-react";

export default function MasterAdmin() {
  const router = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.altKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
      if (e.key === "d" && (e.metaKey || e.altKey)) {
        e.preventDefault();
        navigate("/admin");
        setOpen(false);
      }
      if (e.key === "u" && (e.metaKey || e.altKey)) {
        e.preventDefault();
        navigate("/admin/users");
        setOpen(false);
      }
      if (e.key === "t" && (e.metaKey || e.altKey)) {
        e.preventDefault();
        navigate("/admin/instances");
        setOpen(false);
      }
      if (e.key === "i" && (e.metaKey || e.altKey)) {
        e.preventDefault();
        navigate("/admin/insights");
        setOpen(false);
      }
    };
    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  return (
    <Layout
      style={{ backgroundColor: "#fff" }}
      className="max-h-[100vh] min-h-[100vh]"
    >
      <div className="flex flex-row w-full max-h-[100vh] min-h-[100vh]">
        <div>
          <SideBar />
        </div>
        <div className="w-full max-h-[100vh] min-h-[100vh] ">
          <Outlet />
        </div>
      </div>
      {/* <div className="h-[5vh]">
        <HeaderComponent />
      </div> */}
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput placeholder="Type a command or search..." />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          <CommandGroup heading="Suggestions">
            <CommandItem onClick={() => navigate("/admin")}>
              <LayoutDashboard />
              <span>Dashboard</span>
              <CommandShortcut>Alt + D</CommandShortcut>
            </CommandItem>
            <CommandItem onClick={() => navigate("/admin/users")}>
              <User />
              <span>Users</span>
              <CommandShortcut>Alt + U</CommandShortcut>
            </CommandItem>
            <CommandItem onClick={() => navigate("/admin/instances")}>
              <Train />
              <span>Trains</span>
              <CommandShortcut>Alt + T</CommandShortcut>
            </CommandItem>
            <CommandItem onClick={() => navigate("/admin/insights")}>
              <Lightbulb />
              <span>Insights</span>
              <CommandShortcut>Alt + I</CommandShortcut>
            </CommandItem>
          </CommandGroup>
        </CommandList>
      </CommandDialog>
    </Layout>
  );
}
