import { useLocation, useNavigate } from "react-router-dom";

type CellWithNavigationProps = {
  instanceId: string;
  children: React.ReactNode;
};

const CellWithNavigation = ({
  instanceId,
  children,
}: CellWithNavigationProps) => {
  const navigate = useNavigate();
  const pathname = useLocation();
  const handleClick = () => {
    // Navigate to the desired route with the instanceId
    // pathname.pathname.includes("/admin")
    //   ? pathname.pathname.startsWith("/admin/collected-data")
    //     ? navigate(`/admin/manage-data/${instanceId}`)
    //     : navigate(`/admin/manage-instance/${instanceId}`)
    //   : navigate(`/user/manage-instance/${instanceId}`);
    if (pathname.pathname.startsWith("/admin/collected-data")) {
      navigate(`/admin/manage-data/${instanceId}`);
    }
  };

  return (
    <div
      onClick={handleClick}
      style={{
        cursor: `${
          pathname.pathname.startsWith("/admin/collected-data") ? "cursor" : ""
        }`,
      }}
    >
      {children}
    </div>
  );
};

export default CellWithNavigation;
